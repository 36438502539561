<script>
import baseCtrlMixIn from '../utils/baseController.js';
import ExpandableBlock from '../components/ExpandableBlock.vue';
import WatchLaterModal from '../components/WatchLaterModal.vue';
import VideoEventsMixin from '../mixins/VideoEventsMixin.vue';
import { Server } from '../utils/helpers.js';
import RelatedPages from '../components/RelatedContent.vue';

export default {
  name: 'CtrlInstructionPage',
  el: '.instruction-page',
  mixins: [baseCtrlMixIn, VideoEventsMixin],
  components: { ExpandableBlock, WatchLaterModal, RelatedPages },
  computed: {
    mobilePlayerReady () {
      return window.mobilePlayerReady;
    }
  },
  data() {
    return {
      videoPlaying: false,
      page: null,
    };
  },
  async created() {
    const pageId = window.page_id;
    this.getPageDetails(pageId);
  },
  mounted() {
    this.performDescriptionCropping();
  },
  methods: {
    async getPageDetails(pageId) {
      const url = `/api/pages/${pageId}/`;
      const response = await Server.get(url);
      this.page = response;

      return response;
    },
    performDescriptionCropping() {
      const fullText = $('#cropped-content > div').text();
      if (fullText.length > 80) {
        $('#cropped-content').append(
          '<span class="show-more tw-ml-1.5 tw-text-blue-1 tw-font-medium tw-cursor-pointer">Show more</span>',
        );
        $(document).on('click', '.show-more', () => {
          $('#cropped-content').addClass('tw-hidden');
          $('#full-content').removeClass('tw-hidden');
        });
        $(document).on('click', '#show-transcript', () => {
          $('#transcript').removeClass('tw-hidden');
          $('#show-transcript').addClass('tw-hidden');
        });
        $(document).on('click', '#hide', () => {
          $('#transcript').addClass('tw-hidden');
          $('#show-transcript').removeClass('tw-hidden');
          $('#cropped-content').removeClass('tw-hidden');
          $('#full-content').addClass('tw-hidden');
        });
      }
    },
  },
};
</script>
